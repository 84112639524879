import logo from "./logo-black.svg";
import logoWhite from "./logo-white.svg";
import webinarImage from "./DOLCE_BLUE_JASMINE_1920x900_GRIFFE_HP.jpg";
import webinarImage2 from "./DG-light-blue.jpeg";
import "./App.css";
import { useMemo, useEffect, useState } from "react";
import serialize from "form-serialize";
import _ from "lodash";

// const TAPPE = [
//     { label: "9 Novembre - Catania - Hotel Baia Verde 20.30 - 23.00", value: "9 novembre" },
//     { label: "14 Novembre - Napoli - Grand Hotel Vesuvio 20.30 - 23.00", value: "14 novembre" },
//     { label: "20 Novembre - Bologna - I Portici Hotel 20.30 - 23.00", value: "20 novembre" },
//     { label: "22 Novembre - Torino - Hotel Principi di Piemonte - 20.30", value: "22 novembre" },
// ];
const WEBINAR = [
  // { label: "Napoli_3 Ottobre H.20.00 Teatro Salone Margherita - Via Santa Brigida, 67", value: "Napoli_ 3 Ottobre", thankyou: "3 Ottobre H.20.00<br/>Napoli - Teatro Salone Margherita<br/>Via Santa Brigida, 67" },
  // { label: "Roma_7 Ottobre H.20.00 Villa Miani - Via Trionfale, 151", value: "Roma_ 7 Ottobre", thankyou: "7 Ottobre H.20.00<br/>Roma - Villa Miani<br/>Via Trionfale, 151" },
  // { label: "Roma_10 Ottobre H.20.15 Grand Hotel Plaza", value: "Roma_ 10 Ottobre", thankyou: "10 Ottobre H.20.15<br/>Roma - Grand Hotel Plaza" },
  // { label: "Bologna_15 Ottobre H.20.00 Palazzo De Rossi (Pontecchio Marconi) - Via Palazzo Rossi, 14 Pontecchio Marconi", value: "Bologna_ 15 Ottobre", thankyou: "15 Ottobre H.20.00<br/>Bologna - Palazzo De Rossi (Pontecchio Marconi)<br/>Via Palazzo Rossi, 14 Pontecchio Marconi" },
  // { label: "WEBINAR_NEW MAKE-UP DOLCE&GABBANA - 21-10-2024 H.20.30", value: "WEBINAR_NEW MAKE-UP DOLCE&GABBANA - 21-10-2024", thankyou: "21 Ottobre 2024<br/>H.20.30" },
  // { label: "Padova_22 Ottobre H.20.15 Villa Giovanelli Colonna", value: "Padova_ 22 Ottobre", thankyou: "22 Ottobre H.20.15<br/>Padova - Villa Giovanelli Colonna" },
  // { label: "Devotion Male - 20 Febbraio", value: "Devotion Male 20 Febbraio", thankyou: "20 Febbraio 2025<br/>Devotion Male" },
  // { label: "K&Q Parfum - 13 Marzo", value: "KQ Parfum 13 Marzo", thankyou: "13 Marzo 2025<br/>K&Q Parfum" },
  { label: "NOVITA' MAKE-UP & FRESH SKIN COLLECTION_15 Aprile", value: "NOVITA MAKE-UP FRESH SKIN COLLECTION_15 Aprile", thankyou: "15 Aprile 2025<br/>NOVITA' MAKE-UP & FRESH SKIN COLLECTION" },
];

function App() {
  const [puntiVendita, setPuntiVendita] = useState([
    {
      "title": "Thaler Srl - Gmnh",
      "acf": {
        "ragione_sociale": "Thaler Srl - Gmnh",
        "indirizzo": "Via dei Portici, 69",
        "citta": "Laubengasse, Bolzano (BZ)"
      }
    },{
      "title": "Aeroporto Milano Malpensa",
      "acf": {
        "ragione_sociale": "Aeroporto Milano Malpensa",
        "indirizzo": "National Road Aeroporto della Malpensa, 37",
        "citta": "Ferno"
      }
    }, /*{
      "title": "Bergamo Oro al Serio",
      "acf": {
        "ragione_sociale": "Bergamo Orio al Serio",
        "indirizzo": "Via Leonardo Da Vinci, 2",
        "citta": "Orio al Serio (Bergamo)"
      }
    }, {
      "title": "Milano Linate",
      "acf": {
        "ragione_sociale": "Milano Linate",
        "indirizzo": "Viale Forlanini",
        "citta": "Milano"
      }
    }, {
      "title": "Napoli Capodichino",
      "acf": {
        "ragione_sociale": "Napoli Capodichino",
        "indirizzo": "Viale F. Ruffo di Calabria",
        "citta": "Napoli"
      }
    },*/ {
      "title": "Aeroporto Roma Fiumicino",
      "acf": {
        "ragione_sociale": "Aeroporto Roma Fiumicino",
        "indirizzo": "Via dell'Aeroporto di Fiumicino, 320",
        "citta": "Roma"
      }
    },
    {
      "title": "Calcagni Profumeria",
      "acf": {
        "ragione_sociale": "Calcagni Profumeria",
        "indirizzo": "via Magenta, 29",
        "citta": "Gallarate"
      }
    },
    {
      "title": "Sede DG Beauty",
      "acf": {
        "ragione_sociale": "Sede DG Beauty",
        "indirizzo": "VIA KRAMER 18",
        "citta": "MILANO"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA FILLUNGO 75",
        "citta": "LUCCA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "CORSO ITALIA 16",
        "citta": "PISA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA ALBERTO LIONELLO - CENTRO COMMERCIALE PORTA DI ROMA",
        "citta": "ROMA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA SALVATORE TRINCHESE 80",
        "citta": "LECCE"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA FONTEVIVO - CENTRO COMMERCIALE LE TERRAZZE",
        "citta": "LA SPEZIA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA DEL TRITONE 20/21/22/23",
        "citta": "ROMA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA EUGENIO LURAGHI - CENTRO COMMERCIALE ARESE SHOPPING CENTER",
        "citta": "ARESE"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA MEUCCI 6 LOCALITA' SCOPICCI - PARCO COMMERCIALE VIA MEUCCI",
        "citta": "BARBERINO DI MUGELLO"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA SPARANO 140",
        "citta": "BARI"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA XX SETTEMBRE 65/67/69",
        "citta": "BERGAMO"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA RIZZOLI 10",
        "citta": "BOLOGNA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA XX SETTEMBRE 89",
        "citta": "GENOVA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "CORSO BUENOS AIRES > VIA PANFILO CASTALDI 42",
        "citta": "MILANO"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA PORTICO 71 - CENTRO COMMERCIALE ORIO CENTER",
        "citta": "ORIO AL SERIO"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA DIAZ 27",
        "citta": "RAVENNA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIALE CECCARINI 11/13/15",
        "citta": "RICCIONE"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA COLOMBO 714 - CENTRO COMMERCIALE EUROMA 2 (STORE DOUGLAS)",
        "citta": "ROMA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA ROMA 95",
        "citta": "TORINO"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA MAZZINI 74",
        "citta": "VERONA"
      }
    },
    {
      "title": "ESSERBELLA SPA",
      "acf": {
        "ragione_sociale": "ESSERBELLA SPA",
        "indirizzo": "VIA DEI RONCHETTI 20- CENTRO COMMERCIALE ESSELUNGA",
        "citta": "INDUNO OLONA"
      }
    },
    {
      "title": "ESSERBELLA SPA",
      "acf": {
        "ragione_sociale": "ESSERBELLA SPA",
        "indirizzo": "VIA GENERALE GAETANO GIARDINO - CENTRO COMMERCIALE ESSELUNGA",
        "citta": "LISSONE"
      }
    },
    {
      "title": "ESSERBELLA SPA",
      "acf": {
        "ragione_sociale": "ESSERBELLA SPA",
        "indirizzo": "VIA SAN FRANCESCO 30 - CENTRO COMMERCIALE ESSELUNGA",
        "citta": "PIOLTELLO"
      }
    },
    {
      "title": "ESSERBELLA SPA",
      "acf": {
        "ragione_sociale": "ESSERBELLA SPA",
        "indirizzo": "VIA QUARTIERE PIAVE 53 - CENTRO COMMERCIALE ESSELUNGA",
        "citta": "BRONI"
      }
    },
    {
      "title": "ESSERBELLA SPA",
      "acf": {
        "ragione_sociale": "ESSERBELLA SPA",
        "indirizzo": "VIA SEMPIONE - CENTRO COMMERCIALE ESSELUNGA",
        "citta": "CASTELLETTO TICINO"
      }
    },
    {
      "title": "ESSERBELLA SPA",
      "acf": {
        "ragione_sociale": "ESSERBELLA SPA",
        "indirizzo": "VIA EMILIA OVEST- CENTRO COMMERCIALE ESSELUNGA",
        "citta": "PARMA"
      }
    },
    {
      "title": "ANTONINO RUSSOTTI & C. SNC",
      "acf": {
        "ragione_sociale": "ANTONINO RUSSOTTI & C. SNC",
        "indirizzo": "CORSO UMBERTO I 41",
        "citta": "TAORMINA"
      }
    },
    {
      "title": "BONTEMPO PROFUMI SRL",
      "acf": {
        "ragione_sociale": "BONTEMPO PROFUMI SRL",
        "indirizzo": "VIA ANGELO ZUCCARELLI 22",
        "citta": "CAMPOBASSO"
      }
    },
    {
      "title": "BONTEMPO PROFUMI SRL",
      "acf": {
        "ragione_sociale": "BONTEMPO PROFUMI SRL",
        "indirizzo": "CORSO GARIBALDI 289/291",
        "citta": "ISERNIA"
      }
    },
    {
      "title": "GIBIN PROFUMERIE SRL",
      "acf": {
        "ragione_sociale": "GIBIN PROFUMERIE SRL",
        "indirizzo": "VIA CADUTI SUL LAVORO 5",
        "citta": "SUSEGANA"
      }
    },
    {
      "title": "GIBIN PROFUMERIE SRL",
      "acf": {
        "ragione_sociale": "GIBIN PROFUMERIE SRL",
        "indirizzo": "CORSO VITTORIO EMANUELE 29",
        "citta": "CONEGLIANO"
      }
    },
    {
      "title": "PROFUMANIA SRL",
      "acf": {
        "ragione_sociale": "PROFUMANIA SRL",
        "indirizzo": "VIA ROMA 208",
        "citta": "GRUMELLO DEL MONTE"
      }
    },
    {
      "title": "COSE BELLE DI SGOBBA FRANCESCO",
      "acf": {
        "ragione_sociale": "COSE BELLE DI SGOBBA FRANCESCO",
        "indirizzo": "CORSO VITTORIO EMANUELE 32",
        "citta": "ALBEROBELLO"
      }
    },
    {
      "title": "PROFUMERIA CURTI SRL",
      "acf": {
        "ragione_sociale": "PROFUMERIA CURTI SRL",
        "indirizzo": "VIA DELLA SAPIENZA 12",
        "citta": "VITERBO"
      }
    },
    {
      "title": "SABBIONI PROFUMI SRL",
      "acf": {
        "ragione_sociale": "SABBIONI PROFUMI SRL",
        "indirizzo": "VIA IV NOVEMBRE 13",
        "citta": "RAVENNA"
      }
    },
    {
      "title": "SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC",
      "acf": {
        "ragione_sociale": "SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC",
        "indirizzo": "VIALE DEL PIAVE 50/F",
        "citta": "BRESCIA"
      }
    },
    {
      "title": "G.F. RETAIL SRL",
      "acf": {
        "ragione_sociale": "G.F. RETAIL SRL",
        "indirizzo": "VIA ARCHIMEDE 137",
        "citta": "RAGUSA"
      }
    },
    {
      "title": "G.F. RETAIL SRL",
      "acf": {
        "ragione_sociale": "G.F. RETAIL SRL",
        "indirizzo": "VIA DANTE 8",
        "citta": "BAGHERIA"
      }
    },
    {
      "title": "G.F. RETAIL SRL",
      "acf": {
        "ragione_sociale": "G.F. RETAIL SRL",
        "indirizzo": "CORSO ITALIA 5",
        "citta": "CATANIA"
      }
    },
    {
      "title": "G.F. RETAIL SRL",
      "acf": {
        "ragione_sociale": "G.F. RETAIL SRL",
        "indirizzo": "VIA XX SETTEMBRE, 54",
        "citta": "PALERMO"
      }
    },
    {
      "title": "LIVELY SRL",
      "acf": {
        "ragione_sociale": "LIVELY SRL",
        "indirizzo": "STRADA PROVINCIALE 208 KM 2 - CENTRO COMMERCIALE CAROSELLO",
        "citta": "CARUGATE"
      }
    },
    {
      "title": "LIVELY SRL",
      "acf": {
        "ragione_sociale": "LIVELY SRL",
        "indirizzo": "VIA BENEDETTO CROCE 2 - CENTRO COMMERCIALE AUCHAN",
        "citta": "CESANO BOSCONE"
      }
    },
    {
      "title": "LIVELY SRL",
      "acf": {
        "ragione_sociale": "LIVELY SRL",
        "indirizzo": "VIA TOGLIATTI 1 - CENTRO COMMERCIALE AUCHAN",
        "citta": "RESCALDINA"
      }
    },
    {
      "title": "LIVELY SRL",
      "acf": {
        "ragione_sociale": "LIVELY SRL",
        "indirizzo": "PIAZZA NAPOLI 33",
        "citta": "MILANO"
      }
    },
    {
      "title": "LIVELY SRL",
      "acf": {
        "ragione_sociale": "LIVELY SRL",
        "indirizzo": "VIA OSTI 10 > PORTA ROMANA",
        "citta": "MILANO"
      }
    },
    {
      "title": "MARIONNAUD PARFUMERIES ITALIA SRL",
      "acf": {
        "ragione_sociale": "MARIONNAUD PARFUMERIES ITALIA SRL",
        "indirizzo": "CORSO ITALIA 217",
        "citta": "AREZZO"
      }
    },
    {
      "title": "MARIONNAUD PARFUMERIES ITALIA SRL",
      "acf": {
        "ragione_sociale": "MARIONNAUD PARFUMERIES ITALIA SRL",
        "indirizzo": "VIA CAVOUR 104",
        "citta": "FIRENZE"
      }
    },
    {
      "title": "MARIONNAUD PARFUMERIES ITALIA SRL",
      "acf": {
        "ragione_sociale": "MARIONNAUD PARFUMERIES ITALIA SRL",
        "indirizzo": "VIA GINO ALLEGRI 13",
        "citta": "MESTRE"
      }
    },
    {
      "title": "MARIONNAUD PARFUMERIES ITALIA SRL",
      "acf": {
        "ragione_sociale": "MARIONNAUD PARFUMERIES ITALIA SRL",
        "indirizzo": "VIA DEL COMMERCIO 3 - CENTRO COMMERCIALE SHOPPING CENTER",
        "citta": "ANTEGNATE"
      }
    },
    {
      "title": "A & N PROFUMERIE SRL",
      "acf": {
        "ragione_sociale": "A & N PROFUMERIE SRL",
        "indirizzo": "VIA ANTONIO ROSMINI 20/22",
        "citta": "VIAREGGIO"
      }
    },
    {
      "title": "ALINE FIRENZE SRL",
      "acf": {
        "ragione_sociale": "ALINE FIRENZE SRL",
        "indirizzo": "VIA DEI CALZAIUOLI 53/R",
        "citta": "FIRENZE"
      }
    },
    {
      "title": "CARLOTTA SRL",
      "acf": {
        "ragione_sociale": "CARLOTTA SRL",
        "indirizzo": "PIAZZA CARITA' 36",
        "citta": "NAPOLI"
      }
    },
    {
      "title": "DITANO SRL",
      "acf": {
        "ragione_sociale": "DITANO SRL",
        "indirizzo": "PIAZZA CIAIA 18",
        "citta": "FASANO"
      }
    },
    {
      "title": "GALEAZZI PROFUMERIE SAS ",
      "acf": {
        "ragione_sociale": "GALEAZZI PROFUMERIE SAS ",
        "indirizzo": "CORSO GARIBALDI 112",
        "citta": "ANCONA"
      }
    },
    {
      "title": "GE.PRO. SRL",
      "acf": {
        "ragione_sociale": "GE.PRO. SRL",
        "indirizzo": "VIA TRENTO 8",
        "citta": "SONDRIO"
      }
    },
    {
      "title": "GI.DE.CA SRL",
      "acf": {
        "ragione_sociale": "GI.DE.CA SRL",
        "indirizzo": "CORSO GARIBALDI 139",
        "citta": "REGGIO CALABRIA"
      }
    },
    {
      "title": "GRIFFE SRL",
      "acf": {
        "ragione_sociale": "GRIFFE SRL",
        "indirizzo": "VIA ETNEA 189",
        "citta": "CATANIA"
      }
    },
    {
      "title": "NAIMA ROMA SRL",
      "acf": {
        "ragione_sociale": "NAIMA ROMA SRL",
        "indirizzo": "VIA CANDIA 31",
        "citta": "ROMA"
      }
    },
    {
      "title": "NAIMA ROMA SRL",
      "acf": {
        "ragione_sociale": "NAIMA ROMA SRL",
        "indirizzo": "VIA APPIA 10-12",
        "citta": "ROMA"
      }
    },
    {
      "title": "MUZIO PROFUMI SRL",
      "acf": {
        "ragione_sociale": "MUZIO PROFUMI SRL",
        "indirizzo": "VIALE JONIO 363",
        "citta": "ROMA"
      }
    },
    {
      "title": "NAIMA ROMA SRL",
      "acf": {
        "ragione_sociale": "NAIMA ROMA SRL",
        "indirizzo": "VIALE ERITREA 89 G-F",
        "citta": "ROMA"
      }
    },
    {
      "title": "POWER OF BEAUTY SRL",
      "acf": {
        "ragione_sociale": "POWER OF BEAUTY SRL",
        "indirizzo": "VIALE DEI PARIOLI 9 ",
        "citta": "ROMA"
      }
    },
    {
      "title": "NUUR SRL",
      "acf": {
        "ragione_sociale": "NUUR SRL",
        "indirizzo": "VIALE ALFA ROMEO 113",
        "citta": "POMIGLIANO D'ARCO"
      }
    },
    {
      "title": "PARFUMERIE SRL",
      "acf": {
        "ragione_sociale": "PARFUMERIE SRL",
        "indirizzo": "VIALE LEONARDO DA VINCI 189",
        "citta": "PORTICI"
      }
    },
    {
      "title": "PROFUMERIE FREE SPACE SRL",
      "acf": {
        "ragione_sociale": "PROFUMERIE FREE SPACE SRL",
        "indirizzo": "VIALE ISTRIA 112",
        "citta": "ANDRIA"
      }
    },
    {
      "title": "R&S SRL",
      "acf": {
        "ragione_sociale": "R&S SRL",
        "indirizzo": "VIA ZANARDELLI 79",
        "citta": "VILLANUOVA SUL CLISI"
      }
    },
    {
      "title": "ZIGO' PROFUMI SRL",
      "acf": {
        "ragione_sociale": "ZIGO' PROFUMI SRL",
        "indirizzo": "VIA CHIAIA 210",
        "citta": "NAPOLI"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA FRATELLI ROSSELLI 22",
        "citta": "NOVARA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA EMILIA CENTRO 241",
        "citta": "MODENA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA VITTORIO EMANUELE 8",
        "citta": "MONZA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "CORSO CAVOUR 102",
        "citta": "LA SPEZIA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "CORSO CAVOUR 9",
        "citta": "PAVIA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "PIAZZA MATTEOTTI 19/20",
        "citta": "BERGAMO"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "STRANA PADANA VERSO PADOVA 162",
        "citta": "VICENZA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA OSS MAZZURANA 10",
        "citta": "TRENTO"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA XX SETTEMBRE 3",
        "citta": "TREVISO"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "PIAZZA GARIBALDI 21",
        "citta": "PARMA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA SANTA BARBARA - CENTRO COMMERCIALE GLI ORSI",
        "citta": "BIELLA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "CORSO ITALIA 123/125",
        "citta": "PISA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA MATTEI 52 - CENTRO COMMERCIALE VALECENTER",
        "citta": "MARCON"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA PORTICO 71 - CENTRO COMMERCIALE ORIO CENTER",
        "citta": "ORIO AL SERIO"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA FARINI, 11/13",
        "citta": "BOLOGNA"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "VIA SARDEGNA 1",
        "citta": "CAGLIARI"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "VIA AGNELLO",
        "citta": "MILANO"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "VIA SANT'EUPLIO 24",
        "citta": "CATANIA"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "PIAZZA REPUBBLICA 1",
        "citta": "FIRENZE"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "VIA ANIENE 1",
        "citta": "ROMA"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "VIA GIOLITTI 14",
        "citta": "TORINO"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "VIA GAGINI 7",
        "citta": "PALERMO"
      }
    },
    {
      "title": "LA RINASCENTE SPA",
      "acf": {
        "ragione_sociale": "LA RINASCENTE SPA",
        "indirizzo": "VIA DEL TRITONE 58/B",
        "citta": "ROMA"
      }
    },
    {
      "title": "ROSSI PROFUMI SPA",
      "acf": {
        "ragione_sociale": "ROSSI PROFUMI SPA",
        "indirizzo": "VIA A. FRANZONI 2",
        "citta": "REGGIO EMILIA"
      }
    },
    {
      "title": "ROSSI PROFUMI SPA",
      "acf": {
        "ragione_sociale": "ROSSI PROFUMI SPA",
        "indirizzo": "VIA INDIPENDENZA 24",
        "citta": "BOLOGNA"
      }
    },
    {
      "title": "ROSSI PROFUMI SPA",
      "acf": {
        "ragione_sociale": "ROSSI PROFUMI SPA",
        "indirizzo": "VIA CARLO ALBERTO 26",
        "citta": "TORINO"
      }
    },
    {
      "title": "ROSSI PROFUMI SPA",
      "acf": {
        "ragione_sociale": "ROSSI PROFUMI SPA",
        "indirizzo": "VIA VILLANOVA 29  - CENTRO COMMERCIALE CENTRONOVA",
        "citta": "CASTENASO"
      }
    },
    {
      "title": "ROSSI PROFUMI SPA",
      "acf": {
        "ragione_sociale": "ROSSI PROFUMI SPA",
        "indirizzo": "VIA BISAURA 3  - CENTRO COMMERCIALE LE MAIOLICHE",
        "citta": "FAENZA"
      }
    },
    {
      "title": "ROSSI PROFUMI SPA",
      "acf": {
        "ragione_sociale": "ROSSI PROFUMI SPA",
        "indirizzo": "VIA EMILIA OVEST 1480  - CENTRO COMMERCIALE GRANDEMILIA",
        "citta": "MODENA"
      }
    },
    {
      "title": "BEAUTYPROF SPA",
      "acf": {
        "ragione_sociale": "BEAUTYPROF SPA",
        "indirizzo": "VIALE EMILIA - CENTRO COMMERCIALE LE FONTANE",
        "citta": "CATANZARO"
      }
    },
    {
      "title": "CONSORZIO PROFUMERIE ESTASI",
      "acf": {
        "ragione_sociale": "CONSORZIO PROFUMERIE ESTASI",
        "indirizzo": "VIALE DANTE 21",
        "citta": "CASSINO"
      }
    },
    {
      "title": "GARGIULO & MAIELLO SPA",
      "acf": {
        "ragione_sociale": "GARGIULO & MAIELLO SPA",
        "indirizzo": "VIA S. ESPOSITO 32",
        "citta": "AVELLINO"
      }
    },
    {
      "title": "CHIARELLI PROFUMERIE S.R.L.",
      "acf": {
        "ragione_sociale": "CHIARELLI PROFUMERIE S.R.L.",
        "indirizzo": "VIA FARDELLA 284",
        "citta": "TRAPANI"
      }
    },
    {
      "title": "CAMELI MARIA LUISA",
      "acf": {
        "ragione_sociale": "CAMELI MARIA LUISA",
        "indirizzo": "VIALE SECONDO MORETTI 39",
        "citta": "SAN BENEDETTO DEL TRONTO"
      }
    },
    {
      "title": "AMORETTI PROFUMI SRL",
      "acf": {
        "ragione_sociale": "AMORETTI PROFUMI SRL",
        "indirizzo": "CORSO PESCHIERA 265",
        "citta": "TORINO"
      }
    },
    {
      "title": "PROFUMERIA BEGHIN SRL",
      "acf": {
        "ragione_sociale": "PROFUMERIA BEGHIN SRL",
        "indirizzo": "VIA ZABARELLA 87",
        "citta": "PADOVA"
      }
    },
    {
      "title": "CASTELLI PROFUMERIE SRL",
      "acf": {
        "ragione_sociale": "CASTELLI PROFUMERIE SRL",
        "indirizzo": "VIA FRATTINA 54",
        "citta": "ROMA"
      }
    },
    {
      "title": "DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.",
      "acf": {
        "ragione_sociale": "DELLEPIANE MARTELLA SNC DI DELLEPIANE S. E MARTELLA A.",
        "indirizzo": "PIAZZA MARCONI 16/R",
        "citta": "SAVONA"
      }
    },
    {
      "title": "A.M.D. SRL",
      "acf": {
        "ragione_sociale": "A.M.D. SRL",
        "indirizzo": "PIAZZETTA UMBERTO GIORDANO 4",
        "citta": "MILANO"
      }
    },
    {
      "title": "SBRACCIA & C. SRL",
      "acf": {
        "ragione_sociale": "SBRACCIA & C. SRL",
        "indirizzo": "VIA CECCARDO ROCCATAGLIATA CECCARDI 34/R",
        "citta": "GENOVA"
      }
    },
    {
      "title": "SEMINATI SRL",
      "acf": {
        "ragione_sociale": "SEMINATI SRL",
        "indirizzo": "CORSO BRIANZA 6",
        "citta": "MARIANO COMENSE"
      }
    },
    {
      "title": "FARMACIA VATICANA",
      "acf": {
        "ragione_sociale": "FARMACIA VATICANA",
        "indirizzo": "VIA DELLA STAZIONE VATICANA",
        "citta": "CITTA' DEL VATICANO"
      }
    },
    {
      "title": "FARMACIA VATICANA",
      "acf": {
        "ragione_sociale": "FARMACIA VATICANA",
        "indirizzo": "VIA DELLA POSTA",
        "citta": "CITTA' DEL VATICANO"
      }
    },
    {
      "title": "CONSORZIO PROFUMERIE ESTASI",
      "acf": {
        "ragione_sociale": "CONSORZIO PROFUMERIE ESTASI",
        "indirizzo": "VIA ALDO MORO 237",
        "citta": "FROSINONE"
      }
    },
    {
      "title": "G.F. RETAIL SRL",
      "acf": {
        "ragione_sociale": "G.F. RETAIL SRL",
        "indirizzo": "VIA DELLA PALOMBELLA 30 ",
        "citta": "ROMA"
      }
    },
    {
      "title": "SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC",
      "acf": {
        "ragione_sociale": "SBRUZZI PROFUMERIA DI SBRUZZI TIZIANA & C. SNC",
        "indirizzo": "VIALE SORELLE AMBROSETTI 10 - CENTRO COMMERCIALE NUOVO FLAMINIA",
        "citta": "BRESCIA"
      }
    },
    {
      "title": "GALEAZZI PROFUMERIE SAS ",
      "acf": {
        "ragione_sociale": "GALEAZZI PROFUMERIE SAS ",
        "indirizzo": "VIA BRANCA 35",
        "citta": "PESARO"
      }
    },
    {
      "title": "URBANI 1964 SRL",
      "acf": {
        "ragione_sociale": "URBANI 1964 SRL",
        "indirizzo": "VIA VITTORELLI 39/41 ",
        "citta": "BASSANO DEL GRAPPA"
      }
    },
    {
      "title": "PARFUMERIE SRL",
      "acf": {
        "ragione_sociale": "PARFUMERIE SRL",
        "indirizzo": "VIA BARBARULO 39",
        "citta": "NOCERA INFERIORE"
      }
    },
    {
      "title": "ANNA SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "ANNA SRL UNIPERSONALE",
        "indirizzo": "CORSO VITTORIO EMANUELE 163",
        "citta": "SALERNO"
      }
    },
    {
      "title": "IDEALYS SRL",
      "acf": {
        "ragione_sociale": "IDEALYS SRL",
        "indirizzo": "COSRSO VANNUCCI 88/92",
        "citta": "PERUGIA"
      }
    },
    {
      "title": "CARLOTTA SRL",
      "acf": {
        "ragione_sociale": "CARLOTTA SRL",
        "indirizzo": "VIA DOMENICO MORELLI, 23/25/31",
        "citta": "NAPOLI"
      }
    },
    {
      "title": "CARLOTTA SRL",
      "acf": {
        "ragione_sociale": "CARLOTTA SRL",
        "indirizzo": "VIA PLINIO - CC MAXIMALL POMPEII",
        "citta": "TORRE ANNUNZIATA"
      }
    },
    {
      "title": "GALEAZZI PROFUMERIE SAS ",
      "acf": {
        "ragione_sociale": "GALEAZZI PROFUMERIE SAS ",
        "indirizzo": "PIAZZA TRE MARTIRI 27",
        "citta": "RIMINI"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA ANTONIO MEUCCI",
        "citta": "BARBERINO DEL MUGELLO"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA FEDERICO FELLINI 1 - FIDENZA VILLAGE",
        "citta": "FIDENZA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "CORSO SANT'ANASTASIA 6",
        "citta": "VERONA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA TORINO 36",
        "citta": "MILANO"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "CONTRADA SPALLA - PARCO COMMERCIALE BELVEDERE",
        "citta": "MELILLI"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA PLINIO - CC MAXIMALL POMPEII",
        "citta": "TORRE ANNUNZIATA"
      }
    },
    {
      "title": "DOUGLAS ITALIA SPA",
      "acf": {
        "ragione_sociale": "DOUGLAS ITALIA SPA",
        "indirizzo": "VIA GIOLITTI 2",
        "citta": "ROMA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA M. BUSSATO 74 - CENTRO COMMERCIALE ESP",
        "citta": "RAVENNA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA MAESTRI DEL LAVORO 42 - CC GRANFIUME",
        "citta": "PORDENONE"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA PIETRO ARDUINO 20 - CC NAVE DE VERO",
        "citta": "MARGHERA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "VIA ROMA 49/51",
        "citta": "CASALE MONFERRATO"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "CORSO ROMA 94/967",
        "citta": "ALESSANDRIA"
      }
    },
    {
      "title": "PINALLI SRL UNIPERSONALE",
      "acf": {
        "ragione_sociale": "PINALLI SRL UNIPERSONALE",
        "indirizzo": "PIAZZA CASCINA MOIE 1/2 - FRANCIACORTA OUTLET VILLAGE",
        "citta": "RODENGO SAIANO"
      }
    },
    {
      "title": "GENERAL SRL",
      "acf": {
        "ragione_sociale": "GENERAL SRL",
        "indirizzo": "VIA BARSANTI 15",
        "citta": "FORTE DEI MARMI"
      }
    }
  ]);
  const [pv, setPv] = useState("");
  const [citta, setCitta] = useState([]);
  const [indirizzi, setIndirizzi] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [tappaSelected, setTappaSelected] = useState("");
  const [webinarSelected, setWebinarSelected] = useState("");
  const [isErrored, setIsErrored] = useState(false);

  const nomiPuntiVendita = useMemo(() => {
    let result = {};
    puntiVendita.forEach((el) => {
      result[el.acf?.ragione_sociale] = el.acf?.ragione_sociale;
    });

    return Object.values(result).sort();
  }, [puntiVendita]);

  // useEffect(() => {
  //     fetch("https://wordpress-1002388-4728144.cloudwaysapps.com/wp-json/dolcegabbana/v1/punti-vendita")
  //         .then((response) => response.json())
  //         .then((data) => setPuntiVendita(data));
  // }, []);

  const changeCitta = (puntoVendita) => {
    const newCitta = _.uniqBy(
      puntiVendita.filter((el) => {
        return el.title === puntoVendita;
      }),
      (el) => el.acf?.citta
    );

    newCitta.sort((a, b) => a.acf.citta.toLowerCase().localeCompare(b.acf.citta.toLowerCase()));

    setCitta(newCitta);
  };

  const selectIndirizzi = (citta) => {
    const newIndirizzi = puntiVendita.filter((el) => {
      return el.acf?.citta === citta && el.acf?.ragione_sociale === pv;
    });
    newIndirizzi.sort((a, b) => a.acf.indirizzo.toLowerCase().localeCompare(b.acf.indirizzo.toLowerCase()));
    setIndirizzi(newIndirizzi);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    let body = serialize(form, { hash: true });

    const formData = new FormData();
    for (const name in body) {
      formData.append(name, body[name]);
    }

    formData.append("newsletter", "false");
    body['newsletter'] = "no";

    setIsSubmitting(true);
    const response = await fetch(
      // "https://wordpress-1002388-4728144.cloudwaysapps.com/wp-json/contact-form-7/v1/contact-forms/12643/feedback",
      "https://wordpress-1002388-4728144.cloudwaysapps.com/wp-json/custom/v1/submit-form/12643",
      // {
      //   method: 'POST',
      //   body: formData
      // }
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      }
    );

    if (!response.ok) {
      setIsSubmitting(false);
    } else {
      const data = await response.json();
      console.log("Validation result:", data)
      if (data.status === "validation_failed") {
        const tappaError = data.invalid_fields.find((item) => item.field === "webinar");

        if (tappaError) {
          setIsErrored(true);
          // Submit to waiting list
          await fetch(
            "https://wordpress-1002388-4728144.cloudwaysapps.com/wp-json/contact-form-7/v1/contact-forms/12659/feedback",
            {
              method: "POST",
              body: formData,
            }
          );
        }
      }

      setIsSubmitting(false);
      setIsSubmitted(true);

      // const tappa = TAPPE.find((item) => item.value === body.tappa);
      // setTappaSelected(tappa.label);

      const webinar = WEBINAR.find((item) => item.value === body.webinar);
      setWebinarSelected(webinar.thankyou);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <div>
      <header className="App-header items-start md:items-center">
        <img src={logo} alt="logo" className="px-4 max-w-[196px] md:max-w-[362px]" />
      </header>

      <div className="container">
        {isSubmitted ? (
          <>
            {!isErrored ? (
              <>
                <h2 className="text-2xl font-bold py-8 mt-8 text-center">ISCRIZIONE AVVENUTA</h2>
                <p className="text-center">L'appuntamento è il</p>
                <p className="text-center font-bold text-lg my-4" dangerouslySetInnerHTML={{ __html: webinarSelected }}></p>
                {/* <p className="text-center">Riceverai una mail <br/>con il link allo zoom webinar</p> */}
              </>
            ) : (
              <>
                <h2 className="text-2xl font-bold py-8 mt-8 text-center">
                  Ci spiace!
                </h2>
                <p className="text-center mb-4">
                  La tua iscrizione NON è confermata.<br />
                  Abbiamo raggiunto il numero massimo di iscrizioni.
                </p>
                <p className="text-center">
                  Terremo comunque il tuo contatto per creare la lista d'attesa. Ti contatteremo se avessimo delle possibilità.<br />
                  <br /><br />Un caro saluto,<br />Team Formazione Dolce&Gabbana Italia
                </p>
              </>
            )}
          </>
        ) : isSubmitting ? (
          <div className="py-8 mt-8 text-center text-2xl">
            <svg
              className="animate-spin mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 512 512"
            >
              <path d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z" />
            </svg>
          </div>
        ) : (
          <form className="form" onSubmit={onSubmit}>
            <div className="form-group">
              <label>NOME *</label>
              <input type="text" name="nome" placeholder="IL TUO NOME" required />
            </div>
            <div className="form-group">
              <label>COGNOME *</label>
              <input type="text" name="cognome" placeholder="IL TUO COGNOME" required />
            </div>
            <div className="form-group">
              <label>MAIL *</label>
              <input type="email" name="email" placeholder="LA TUA E-MAIL" required />
            </div>
            <div className="form-group">
              <label>NUMERO DI TELEFONO</label>
              <input type="tel" name="tel" placeholder="IL TUO NUMERO DI TELEFONO" />
            </div>

            <h2 className="text-lg">PUNTO VENDITA</h2>
            <div className="form-group">
              <label>NOME DEL PUNTO VENDITA *</label>
              <div className="relative">
                <select
                  required
                  name="puntoVendita"
                  onChange={(e) => {
                    setPv(e.target.value);
                    changeCitta(e.target.value);
                    selectIndirizzi(citta);
                  }}
                >
                  <option value="">Seleziona un punto vendita</option>
                  {nomiPuntiVendita.map((pv) => (
                    <option key={pv}>{pv}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>CITTA *</label>
              <div className="relative">
                <select name="citta" onChange={(e) => selectIndirizzi(e.target.value)} required>
                  <option value="">Seleziona una città</option>
                  {citta.map((pv) => (
                    <option key={pv.title + pv.acf?.citta}>{pv.acf?.citta}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>INDIRIZZO *</label>
              <div className="relative">
                <select name="indirizzo" required>
                  <option value="">Indirizzo del punto vendita</option>
                  {indirizzi.map((pv) => (
                    <option key={pv.acf?.indirizzo + pv.title}>{pv.acf?.indirizzo}</option>
                  ))}
                </select>
              </div>
            </div>

            <h2 className="text-lg">SCEGLI LA TUA DATA</h2>
            <div className="form-group">
              <label>SELEZIONA LA DATA *</label>
              <div className="relative">
                <select name="webinar" required>
                  <option value="">Seleziona la data</option>
                  {WEBINAR.map((item, index) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="mb-16 checkbox-container mt-4">
              <label className="block mb-2 text-[14px]">
                <input type="checkbox" name="profiling" value="yes" defaultChecked={false} required />{" "}
                Ho preso visione sull’
                <a
                  href="/privacy-policy.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="text-underline"
                >
                  informativa privacy
                </a>
              </label>
            </div>
            <div className="mt-4 mb-12">
              <button className="uppercase bg-black hover:bg-grey text-white w-full md:w-[150px] h-[40px] mt-4 mb-8">
                Invia
              </button>

              {/* <img src={webinarImage} className="mx-auto mb-2 max-w-[99%] ld:max-w-[90%] h-auto" alt="D&G webinar campaign image" />

                            <img src={webinarImage2} className="mx-auto mb-8 max-w-[99%] ld:max-w-[90%] h-auto" alt="D&G webinar campaign image" /> */}
            </div>
          </form>
        )}
      </div>

      <footer className="bg-black pt-16 pb-16 text-center text-white text-[8px]">
        <img src={logoWhite} className="mx-auto mb-8" alt="logo" />
        Site – managed by The Level S.r.l – Copyright © Dolce & Gabbana S.r.l. 2023 – All rights reserved – any
        reproduction of the contents is strictly forbidden
      </footer>

      {/* Version: 0.1 */}
    </div>
  );
}

export default App;
